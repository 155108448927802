import React from "react"

import "../scss/contact.scss"

const Contact = ({ data }) => {
  const { frontmatter } = data[0].node;
    return(
      <div className="contact" id="contact">
        <h1>{frontmatter.title}</h1>
        <p>{frontmatter.text}</p>
        <a href="mailto:nguyenthanhkhoi0@gmail.com" className="contact__button" rel="nofollow noopener noreferrer">{frontmatter.button}</a>
      </div>
    )
};

export default Contact
