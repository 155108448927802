import React, {useEffect, useRef } from "react"
import { useIntersection } from "react-use"
import {Helmet} from "react-helmet";

import Layout from "../components/layout"
import Hero from "../components/hero"
import Carousel from "../components/carousel"
import Archive from "../components/archive"
import Contact from "../components/contact"
import Footer from "../components/footer"
import SEO from "../components/seo"
import gsap from "gsap"

const IndexPage = ({data}) => {

  const archiveItem = useRef(null);

  const intersection = useIntersection(archiveItem, {
    root: null,
    rootMargin: "0px",
    threshold: 0.1
  });

  gsap.config({
    nullTargetWarn: false,
  });

  const fadeIn = (element) => {
    gsap.to(element, 0, {
      opacity: 1,
      ease: "power2.out",
      stagger: {
        amount: 1.3
      }
    });
  };

  const fadeOut = (element) => {
    gsap.to(element, 1, {
      opacity: 1,
      ease: "power2.out",
    });
  };

  useEffect(()=> {
    gsap.to('body', 0, {css: {visibility: "visible"} });
    const tl = gsap.timeline();
    tl.from(".hero h1, .hero p, .title", 1,{
      opacity: 0,
      y: -10,
      ease: "power4.out",
      stagger:{
        amount: 0.3
      }
    }).to(".carousel__item__overlay", 1, {
      css: {left: "100%"},
      ease: "power2.out",
      delay: "-1",
      stagger:{
        amout: 0.3,
      }
     });

  }, [])
  intersection && intersection.intersectionRatio > 0.1
   ? fadeIn(".archive__item")
   : fadeOut();



  return(
    <>
      <Helmet />
      <Layout>
        <SEO title="Home" />
        <Hero data={data.hero.edges} />
        <Carousel data={data.featured.edges}/>
        <Archive id="archive" data={data.projects.edges} toAnimate={archiveItem}/>
        <Contact id="contact" data={data.contact.edges} />
        <Footer />
      </Layout>
    </>
  )

}

export default IndexPage

export const query = graphql`
  {
    hero: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/hero/"}}) {
      edges {
        node {
          frontmatter {
            title
            text
            external
          }
        }
      }
    }
    featured: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/featured/"}}) {
      edges {
        node {
          frontmatter {
            title
            video{
              publicURL
            }
            external
            tech
            task
          }
          html
        }
      }
    }
    projects: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/projects/" }
        frontmatter: { showInProjects: { ne: false } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            tech
            github
            external
          }
          html
        }
      }
    }
    contact: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/contact/"}}) {
      edges {
        node {
          frontmatter {
            title
            text
            button
          }
          html
        }
      }
    }
  }
`
