import React from "react"

import "../scss/footer.scss"

const Footer = ({ data }) => (
  <footer className="footer">
    <div className="wrapper">
      <a href="https://github.com/khoilerspoiler" target="_blank" rel="nofollow noopener noreferrer" aria-label="External Link">© 2020 Khoi Nguyen</a>
    </div>
  </footer>
)

export default Footer
