import React, { useEffect } from 'react';
import IconLink from "../icons/link.svg";

import "../scss/carousel.scss";


const Carousel = ({data, toAnimate}) => {

  var start;
  var offset;

  useEffect(() => {
    addEventListeners();
    handleEvents();
  }, [])

  const addEventListeners = () => {

    document.querySelectorAll(".carousel").forEach(el => {
      el.addEventListener("mousedown", onMouseDown);
      el.addEventListener("mouseup", onMouseUp);
      el.addEventListener("mouseout", onMouseOut);
      el.addEventListener("touchstart", onTouchStart, false);
      el.addEventListener("touchend", onTouchEnd);
    });
     window.addEventListener('resize', onResize);
  };

  const removeEventListeners = () => {
    document.querySelectorAll(".carousel").forEach(el => {
      el.removeEventListener("mousedown", onMouseDown);
      el.removeEventListener("mouseup", onMouseUp);
      el.removeEventListener("mouseout", onMouseOut);
      el.removeEventListener("touchstart", onTouchStart);
      el.removeEventListener("touchend", onTouchEnd);
      console.log('listeners removed');
    });
  };

  const onTouchStart = (e) => {
    offset = giveMeIntValOf(document.querySelector('.carousel__wrapper').style.transform);
    start = e.touches[0].clientX;
    document.querySelector(".carousel").addEventListener("touchmove", onTouchMove);
  }

  const onTouchMove = (e) => {
    const walk = start - e.touches[0].clientX;
    document.querySelector('.carousel__wrapper').style.transform = `translateX(${offset - walk * 2}px)`;
  }

  const handleSnap = () => {
    var end = giveMeIntValOf(document.querySelector('.carousel__wrapper').style.transform);

    var overshoot = document.querySelector('.carousel').offsetWidth - document.querySelector('.carousel__wrapper').offsetWidth;
    if(end < overshoot ){
      document.querySelector('.carousel__wrapper').style.cssText = `transform: translateX(${overshoot}px); transition: transform 0.1s cubic-bezier(.25,.72,.51,.96);`
    }
    else if (end > 0) {
      document.querySelector('.carousel__wrapper').style.cssText = `transform: translateX(0); transition: transform 0.1s cubic-bezier(.25,.72,.51,.96) `;
    }
  }

  const onTouchEnd = (e) => {
    document.querySelectorAll(".carousel").forEach(el => {
      el.removeEventListener("touchmove", onTouchMove);
    });
    handleSnap();
  }

  const onMouseDown = (e) => {
      offset = giveMeIntValOf(document.querySelector('.carousel__wrapper').style.transform);
      start = e.pageX;
      document.querySelector(".carousel").addEventListener("mousemove", onMouseMove);
   };

   const onMouseMove = (e) => {
     const walk = start - e.pageX;
     document.querySelector('.carousel__wrapper').style.transform = `translateX(${offset - walk * 2}px)`;
   };

   const onMouseUp = (e) => {
     document.querySelectorAll(".carousel").forEach(el => {
       el.removeEventListener("mousemove", onMouseMove);
     });
     handleSnap();
    };

  const onMouseOut = (e) => {
    document.querySelectorAll(".carousel").forEach(el => {
      el.removeEventListener("mousemove", onMouseMove);
      el.removeEventListener("touchmove", onMouseMove);
    });
    handleSnap();
  }

  const handleEvents = () => {
    if(window.matchMedia("(min-width: 1100px)").matches){
      addEventListeners();
    }
    else {
      removeEventListeners();
    }
  }

  const onResize = () => {
    handleSnap();
    handleEvents();
  }

  const giveMeIntValOf = (el) => {
    return parseInt(el.replace("translateX(", "").replace("px)", ""), 10);
  };



  //Graphql queries
  const featured = data.filter(({ node }) => node);
    return(
      <>
      <h2 className="carousel__title title">Featured</h2>
      <div className="carousel" id="featured">
        <div className="carousel__wrapper" style={{
          transform: `translateX(0)`
        }}>
            {featured && featured.map(({node}, i) => {
              const { frontmatter, html } = node;
              const { title, external, tech, video } = frontmatter;
              return(
                <div className="carousel__item" key={i} ref={toAnimate}>
                  <div className="carousel__item__overlay"></div>
                    <video className="carousel__item__image" loop autoPlay muted>
                      <source src={video.publicURL} type="video/mp4" />
                    </video>
                  <div className="carousel__item__info">
                    <span className="carousel__item__title">{title}</span>
                    <div className="carousel__item__tech">{tech.map((tech, i) => (
                      <span key={i}>{tech}</span>
                    ))}</div>
                    <div className="carousel__item__description" dangerouslySetInnerHTML={{ __html: html }}></div>
                    <a className="carousel__item__link" href={external} target="_blank" rel="nofollow noopener noreferrer" aria-label="External Link"><IconLink />Link zur Seite</a>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
      </>
    )
}




export default Carousel;
