import React from "react"

import "../scss/hero.scss"
import Mail from "../icons/mail.svg";
import Code from "../icons/code.svg";

const Hero = ({ data }) => {

  const { frontmatter } = data[0].node;

  return(
    <div className="hero">
      <h1 className="hero__title">{frontmatter.title}</h1>
      <p className="hero__text">{frontmatter.text}</p>
      <a href="mailto:nguyenthanhkhoi0@gmail.com" className="hero__mail">E-Mail<Mail /></a>
      <a href={frontmatter.external} className="hero__github" target="_blank" rel="nofollow noopener noreferrer">GitHub<Code /></a>
    </div>
  );

};

export default Hero
