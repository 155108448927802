
import React from "react"
import IconLink from "../icons/link.svg";
import IconCode from "../icons/code.svg";

import "../scss/archive.scss"

const Archive = ({data, toAnimate}) => {
  const projects = data.filter(({ node }) => node);
  return(
    <>
    <h2 className="archive__title title">Archiv</h2>
    <div className="archive" id="archive" ref={toAnimate}>
      { projects && projects.map(({node}, i) => {
        const { frontmatter, html } = node;
        const { title, external, tech, github } = frontmatter;
        return(
          <div className="archive__item"  key={i}>
            <div className="archive__item__inner">
              <div className="archive__item__title">
                {title}
              </div>
              <a className="archive__item__link"
               href={external} target="_blank"
                rel="nofollow noopener noreferrer"
                aria-label="External Link"><IconLink />Link zur Seite
                </a>
              {github && (
                    <a
                      className="archive__item__link"
                      href={github}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      aria-label="GitHub Link"><IconCode />GitHub
                    </a>
                  )}
              <div className="archive__item__description" dangerouslySetInnerHTML={{ __html: html }}>
              </div>

              {tech && (
                <div className="archive__item__properties">
                    {tech.map((tech, i) => (
                      <span key={i}>{tech}</span>
                    ))}
                </div>
                )}
            </div>
          </div>
        )
      }
        )
      }
     </div>
     </>
  );
}


export default Archive
